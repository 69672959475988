.persons-form {

    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    border-radius: 8px;

    &__action {
        display: flex;
        justify-content: space-between;
    }
}