$parent: '.slot';
$imgSize: 50px;
$slotBgColor: white;
$emptySlotBgColor: rgba($slotBgColor, .3);
$emptySlotColorText: #688569;
$breakSlotBgColor: rgba(tomato, .3);
$breakSlotColorText: #f0ded4;
$borderRadius: 4px;

$colorGreen: #19b495;
$colorPurple: #907a9e;


.resourceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: $imgSize;
  height: $imgSize;
  border-radius: 50%;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center top;
  }
}

.slot {
  display: flex;
  flex-direction: column;
  background: $slotBgColor;
  height: 100%;
  border-radius: $borderRadius;
  &:not(&_break):hover  {
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(black, .3);
    background: $slotBgColor;
  }

  &_empty {
    background: $emptySlotBgColor;
    .header {
      color: $emptySlotColorText;
      background: none;
      justify-content: flex-start;
      align-items: center;
      font-size: .7rem;
    }
    .content {
      text-align: center;
      color: $emptySlotColorText;
    }
  }
  &_busy {
    .header {
      $size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__payment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        
        border-radius: 50%;
        margin-left: auto;
        svg {
          fill: white;
          width: calc(#{$size} + 4px);
          height: calc(#{$size} + 4px);
        }

        &_paid {
          background: green;
        }
        &_nopaid {
          background: tomato;
        }
      }
    }
  }
  &_break {
    &:hover {
      cursor: not-allowed;
    }
    background: $breakSlotBgColor;
    color: $breakSlotColorText;
    .header {
      background: none;
      justify-content: center;
    }
    .content {
      text-align: center;
      color: $breakSlotColorText;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;

  background: $colorGreen;
  padding: 3px 5px;
  font-size: 0.5rem;
  font-weight: bold;
}

.header span {
  font-weight: bold;
  &:first-child {
    margin-right: 5px;
    font-size: 0.6rem;
  }
  &:last-child {
    text-transform: uppercase;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px;
  color: #313131;
  font-weight: bold;
  overflow: hidden;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  text-transform: uppercase;
  height: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;

  }
  span {
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    font-size: .6rem;
    overflow: hidden;
    width: 100%;
    &:last-child {
      font-size: .7rem;
    }
  }

  &_wait {}
}

@media (max-width: 760px) {
  .content {
    span {
      &:last-child {
        font-size: .6rem;
        white-space: initial;
      }
    }
  }
}