.chartWrapper {
  width: 1200px;
  overflow-x: auto;
}
.chart {
  width: 1200px;
}

@media (max-width: 860px) {
  .chartWrapper {
    width: 100%;
  }
  .chart {
    width: 860px;
    canvas {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .chart {
    width: 700px;
  }
}