.fc {



    .fc-view-harness {
        overflow-x: auto;
        height: auto !important;

        >.fc-view {
            min-width: 880px;
        }
    }

    .fc-scrollgrid-liquid {
        >tbody {
            min-height: 1206px;
        }
    }

    .fc-view-harness-active>.fc-view {
        position: relative !important;
    }

    .fc-scroller-liquid-absolute {
        position: relative !important;
    }

    .fc-timegrid-slot {
        height: 30px !important;

        @media screen and (max-width: 920px) {
            height: 35px !important;
        }
    }
}