

.schedule
  
  display: flex
  flex-direction: column
  
  
  
  &__day
    display: grid
    grid-template-columns: repeat(25, 1fr)
    align-items: center
    border-bottom: 1px solid #000

    &:nth-child(2)
        border-top: 1px solid #000
    &--header
      .schedule__time:last-child
        display: block
        

  &__time
    width: 100%
    min-width: 20px
    height: 100%
    padding: 10px
    border-left: 1px solid #000

    display: flex
    justify-content: center
    align-items: center

    input:checked + .schedule__circle
      background: #999

    &:hover
      //background: #eee
      cursor: pointer

    &:nth-child(25)
       border-right: 1px solid #000

      

  &__circle

    display: flex
    width: 20px
    min-width: 20px
    height: 20px
    border-radius: 50%
    background: transparent
    justify-content: center

    &--red
      background: red!important

.schedule-duplicator 
  display: flex
  align-items: center
  padding-top: 20px
  > div
    padding: 10px
  input 
    width: 30px
    height: 10px
