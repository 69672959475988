.authorization {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authorization-title {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
}

.authorization-form {
    position: relative;
    margin: 0 auto;
    padding: 24px;
    width: 560px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    border: 1px solid #000;
    border-radius: 8px;

    &__action {
        display: flex;
        justify-content: space-between;
    }
}

.reset-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}

.phone-input-block {
    position: relative;
    margin-bottom: 10px;
}