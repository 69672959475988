@import "_reset.scss";


:root {
    --main-color: #333333;
    --main-color2: #1976d2;
    --secondary-color: gray;
    --icon-color: #757575;
    --border-color: #e0e0e0;
    --main-color2-hover: rgb(17, 82, 147);
    --form-gap: 22px;
    --fc-bg-color: #f5f5f5;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, 
.fc-timegrid-event.fc-event-mirror, 
.fc-timegrid-more-link {
    box-shadow: none;
    border-bottom: 2px solid transparent;
}

.fc-timegrid-body {
    background-color: var(--fc-bg-color);
}

.fc-timegrid-slots colgroup {
    background: white;
}

body {
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: 'Roboto', sans-serif;
    background: #fff;
    font-size: 16px;
    line-height: 1;

    &.no-scroll {
        overflow-y: hidden;
    }

    * {
        box-sizing: border-box;
    }

    button {
        font-family: 'Roboto', sans-serif;
    }

    input {
        font-family: 'Roboto', sans-serif;

        &::placeholder {
            font-size: inherit;
            font-family: 'Roboto', sans-serif;
        }
    }

    textarea {
        font-family: 'Roboto', sans-serif;

        &::placeholder {
            font-size: inherit;
            font-family: 'Roboto', sans-serif;
        }
    }
}


.separator {
    height: 1px;
    background: silver;
    width: 100%;
    margin: 20px 0;
}

.wrapper {
    overflow: hidden;
}

.usn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.inside {
    width: 1210px;
    max-width: 100%;
    padding: 0 15px;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
}

.mob {
    display: none;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}


section {
    padding: 1px 0;
}

.link {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: var(--main-color2);
}

.table-action {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    @media screen and (max-width:1220px) {
        flex-wrap: wrap;
    }

    &__block {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        .input-wrap {
            min-width: 220px;

            &--type2 {
                min-width: 280px;
            }
        }

        .button-icon {
            width: 24px;
        }


        @media screen and (max-width:1220px) {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            >div {
                width: 100% !important;
            }

            .input-wrap {
                width: 100%;
            }

            button {
                width: 100%;
            }
        }

        @media screen and (max-width:620px) {
            grid-template-columns: repeat(1, 1fr);
        }

    }
}

.title {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 500;
    text-align: left;

    &--mb24 {
        margin-bottom: 24px;
    }

    &--mb36 {
        margin-bottom: 36px;
    }

}

.input-wrap {
    position: relative;

    &--line {
        display: flex;
        align-items: center;
    }

    .button-icon {
        margin-left: 0;
        position: absolute;
        right: -32px;
        top: 8px;
    }
}

.input-button-icon {
    position: absolute !important;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: auto !important;
}


.create-edit-form {
    margin-left: -15px;
    width: calc(100% + 30px);
    max-width: calc(100% + 30px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    border-radius: 8px;

    &__action {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width:560px) {
        margin-left: -20px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px);
    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    user-select: none;
}

// charts

//table

.table-block {
    margin-bottom: 44px;
}

.table {
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    border: 1px solid var(--ui-border-color);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    word-break: break-word;
    white-space: normal;

    @media screen and (max-width:1199px) {
        .tr {
            padding: 12px;
            grid-template-columns: repeat(4, 1fr) !important;
            gap: 16px 8px;
            align-items: flex-start;
        }

        .td {
            padding: 0 !important;
            position: relative;
            width: 100%;

            &--right {
                text-align: left;
            }
        }

        .table-buttons {
            justify-content: flex-start;
        }
    }


    @media screen and (max-width:620px) {
        .tr {
            grid-template-columns: repeat(2, 1fr) !important;
        }

        .td {
            position: relative;
            width: 100%;
        }
    }

    &--mob-1660 {
        @media screen and (max-width:1660px) {
            .tr {
                padding: 12px;
                grid-template-columns: repeat(4, 1fr) !important;
                gap: 16px 8px;
                align-items: flex-start;
            }

            .td {
                padding: 0 !important;
                position: relative;
                width: 100%;

                &--right {
                    text-align: left;
                }
            }

            .table-buttons {
                justify-content: flex-start;
            }

            .table-header {
                display: none;
            }

            .td-hidden-name {
                display: block;
            }
        }


        @media screen and (max-width:620px) {
            .tr {
                grid-template-columns: repeat(2, 1fr) !important;
            }

            .td {
                position: relative;
                width: 100%;
            }
        }
    }

    &--visit-payment {

        & .tr {
            grid-template-columns: 8fr 1fr;
        }
    }

    // clients table styles
    &--client-certificates {

        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        }
    }

    &--client-abonements {

        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        }
    }

    &--client-visits-history {
        & .tr {
            grid-template-columns: 0.7fr 0.7fr 1fr 1fr 1fr 0.7fr 0.7fr;
        }
    }

    &--clients-financial-analytics {

        & .tr {
            grid-template-columns: 80px 1fr 1fr 1fr 0.7fr 2fr 1fr 1fr 1px;
        }

        & .MuiCollapse-root {

            // background: #f3f3f3;
            th {
                min-width: 80px;
            }
        }
    }

    &--horses {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 130px;
        }

        p {
            span {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    &--horses-categories {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--coaches {
        & .tr {
            grid-template-columns: 1fr .5fr .5fr 1fr 0.5fr .5fr 130px;
        }
    }

    &--units {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--pages {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 130px;
        }
    }

    &--services {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 1fr 0.7fr 0.5fr 0.5fr 0.5fr 130px;
        }
    }

    &--service-categories {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 1fr 130px;
        }
    }

    &--certificates {
        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 130px;
        }
    }

    &--abonnements {
        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr 0.7fr;
        }
    }

    &--certificate-categories {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--season-tickets {
        & .tr {
            grid-template-columns: 1.3fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr 130px;
        }
    }

    &--season-tickets-categories {

        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--inventory-items {

        & .tr {
            grid-template-columns: 1fr 1.5fr 0.9fr 0.9fr 1fr 1fr 1fr 1fr 0.8fr 130px;
        }
    }

    &--inventory-items-availability {

        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--turnovers {
        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 1fr 1fr 1fr;
        }
    }

    &--stocktacking {

        & .tr {
            grid-template-columns: 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr 0.7fr 0.7fr;
        }
    }

    &--stocktacking-edit {
        & .tr {
            grid-template-columns: 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr 0.7fr 2fr;
        }
    }

    &--stocktacking-items {

        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--clients {

        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 100px;
        }
    }

    &--client-card {

        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            font-weight: bold;
        }

        .td-hidden-name {
            display: block;
        }
    }

    &--inventory-item-types {

        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--write-offs {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr 1.5fr 1fr 130px;
        }
    }

    &--write-offs-view {
        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--order-incomings {

        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 130px;
        }
    }

    &--order-inventory-items {

        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 190px 1fr 60px;
            align-items: center;
        }
    }

    &--order-incomings-view {

        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--logs {
        word-break: break-word;
        white-space: normal;

        & .tr {
            grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 100px;
            align-items: center;
        }

        & .td {
            &--right {
                padding: 0;
            }
        }
    }

    // CABINET
    &--personal-visits {

        & .tr {
            grid-template-columns: 0.7fr 0.7fr 1fr 1fr 1fr 0.5fr 0.5fr 180px;
            align-items: center;
        }
    }

    &--personal-history {

        & .tr {
            grid-template-columns: 80px 0.7fr 0.7fr 1fr 1fr 1fr 0.5fr 130px;
            align-items: center;
        }
    }

    &--personal-abonements {

        & .tr {
            grid-template-columns: 0.5fr 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        }
    }

    &--personal-certificates {

        & .tr {
            grid-template-columns: 1fr 1fr 0.7fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        }
    }

    &--wtf {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 130px;
        }
    }


    &--order-inventory-items {

        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 190px 1fr 60px;
        }
    }

    &--order-outcomings {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 200px;
        }
    }

    &--order-outcomings-view {
        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--expenditure-norms {
        & .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 130px;
        }
    }

    &--expenditure-norms-view {
        & .tr {
            grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &--user-categories {
        & .tr {
            grid-template-columns: 1fr 2fr 1fr 130px;
        }
    }

    &--persons {
        & .tr {
            grid-template-columns: 70px 1.3fr 0.8fr 1.4fr 1fr 1fr 1fr 0.8fr 0.8fr 0.8fr 100px;
        }
    }

    &--users {
        & .tr {
            grid-template-columns: 70px 1fr 1fr 1fr 1fr 1fr 1fr 100px;
        }
    }


}

// 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px

.tr {
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr 1fr;

}

.td {
    padding: 16px 4px;

    &:first-child {
        padding-left: 16px;
        // color: #d9d9d9;
    }

    &:last-child {
        padding-right: 16px;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--plus {
        background: #c5ffc5;
        font-weight: bold;
    }

    &--minus {
        background: #fdc0b5;
        font-weight: bold;
    }

    &--editable {
        padding: 0;

        input {
            padding: 16px 4px;

            &:focus {

                background: #eaeaea;
            }

            border: none;
            background: transparent;
        }

        &:hover {
            cursor: text;
            background: #eaeaea;
        }
    }

    &--editable-cell {
        background: #f3f3f3;

        input {
            font-weight: bold;
            text-align: center;
        }
    }
}

.table-header {
    .tr {
        align-items: center;
        border-bottom: 1px solid var(--border-color);
    }

    @media screen and (max-width:1199px) {
        display: none;
    }
}

.table-body {
    .tr {
        position: relative;
        border-bottom: 1px solid var(--border-color);

        &:last-child,
        &:only-child {
            border: none;
        }
    }
}

.td-name {
    font-size: 14px;
    line-height: 165%;
    font-weight: 500;
}

.td-content {
    img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        object-position: center;
    }
}

.td-hidden-name {
    margin-bottom: 8px;
    display: none;
    font-size: 14px;
    line-height: 165%;
    font-weight: 500;

    @media screen and (max-width:1199px) {
        display: block;
    }
}

.table-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}


.input-error {

    * {
        color: red !important;
        border-color: red !important;
    }
}

.table-button-short {
    display: flex;
    align-items: center;
    gap: 8px;

    &__arrow {
        min-width: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        svg {
            path {
                fill: var(--icon-color);
            }

            &.active {
                path {
                    fill: var(--main-color2);
                }
            }
        }
    }
}

.table-pagination {
    display: flex;
    justify-content: flex-end;
}


.center-block {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center-block-title {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
}

.center-block-form {
    position: relative;
    margin: 0 auto;
    padding: 24px;
    width: 560px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    border: 1px solid #000;
    border-radius: 8px;

    &__action {
        display: flex;
        justify-content: space-between;
    }
}

.center-block-links {
    margin: 0 auto;
    max-width: 100%;
    width: 560px;
    display: flex;
    gap: 10px;

    @media screen and (max-width:560px) {
        flex-wrap: wrap;

        .button {
            width: 100%;
        }
    }
}

.center-block-title-home {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width:920px) {
        font-size: 28px;
    }
}





.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 50px;
    gap: 8px;
    padding: 0 15px;
    background: var(--main-color2);
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: var(--main-color2-hover);
    }

    &--disable {
        opacity: 0.5;
        cursor: not-allowed;
        background: var(--icon-color);

        &:hover {
            background: var(--icon-color);
        }
    }

    &__arrow {
        display: flex;
        align-items: center;
        width: 6px;
    }

    &__icon {
        display: flex;
        width: 12px;

        .fill path {
            fill: #fff;
        }
    }


    &--type2 {
        color: var(--main-color2);
        border: 1px solid var(--main-color2);
        background: none;
    }

    &--width50p {
        width: 50%;
    }

    &--width100p {
        width: 100%;
    }

    &--center {
        margin: 0 auto;
    }
}




.phone-input {
    display: flex;
    height: 56px;

    .flag-dropdown {
        position: static;
        height: 100%;
        width: 40px;
        order: 1;
        background: none;
        border-right: none;
    }

    .form-control {
        padding: 0 20px;
        width: calc(100% - 40px);
        height: 100%;
        order: 2;
        border-radius: 0 5px 5px 0;
        font-size: 18px;
    }
}


header {
    height: 80px;
    border-bottom: 1px solid #ddd;

    .inside {
        height: 100%;
    }

    @media screen and (max-width:520px) {
        height: auto;
    }
}


.header {
    height: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:920px) {
        padding: 16px 12px;
    }

    @media screen and (max-width:520px) {
        flex-wrap: wrap;

        .logo {
            margin-bottom: 22px;
            width: 100%;
        }

        .header-right {
            width: 100%;
            justify-content: center;
        }
    }
}

.header-right {
    display: flex;
    align-items: center;
    gap: 12px;
}

.logo {
    height: 47px;
    text-align: center;

    a {
        height: 100%;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            filter: invert(100%);
        }
    }


}

.main-booking {
    min-height: calc(100vh - 80px);

    &_admin {
        min-height: initial;
    }
}

.booking-tabs {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    @media screen and (max-width:920px) {
        flex-wrap: wrap;

        .button {
            width: 100%;
        }
    }
}

.booking-wrap {
    padding: 0 15px;
    padding-bottom: 70px;
    margin: 50px auto;
    max-width: 100%;
    width: 620px;

    &_admin {
        margin: 0;
        width: initial;
        padding: 0;
    }
}


.booking-title {
    margin: 50px 0;
    padding: 0 25px;
    position: relative;
    font-size: 30px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width:560px) {
        font-size: 24px;
    }

    &__back {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.booking-services-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__item {
        cursor: pointer;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);

        &.active {
            .booking-services-list {
                &__icon-check {
                    span {
                        &:nth-child(2) {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        &.no-active {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__item-photo {
        height: 200px;
        border-radius: 10px 10px 0 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }

    &__item-info {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        padding-right: 60px;
    }

    &__item-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__item-text {
        font-size: 16px;
        line-height: 1.5;
    }

    &__item-amount {
        font-size: 18px;
        font-weight: 500;
        color: var(--main-color2);
    }

    &__icon-check {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--icon-color);
        border-radius: 100%;
        transform: translateY(-50%);

        span {
            width: 60%;
            height: 2px;
            background: var(--icon-color);
            transition: 0.3s;

            &:nth-child(2) {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
    }
}

.booking-horses-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__item {
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        border: 2px solid transparent;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &.active {
            border-color: var(--main-color2);
        }

        &--center {
            align-items: center;
        }

    }

    &__item-photo {
        margin-top: 6px;
        min-width: 60px;
        width: 60px;
        height: 60px;
        border-radius: 100%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    &__item-info {
        position: relative;
        width: calc(100% - 80px);
    }


    &__item-service {
        margin-bottom: 6px;
        font-size: 15px;
        line-height: 1.2;
        color: var(--secondary-color);

        p {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    &__item-name {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
    }


}

.booking-horses-time-list {
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width:560px) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        padding: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--border-color);
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.025);
        cursor: pointer;

        &.active {
            border-color: var(--main-color2);
        }

        &.current {
            background-color: var(--main-color2);
            color: white;
        }
    }

    &__item-time {
        font-size: 20px;
        font-weight: 500;
    }

    &_admin {
        grid-template-columns: repeat(5, 1fr);
        border: none;
        padding: 0;

        @media screen and (max-width:960px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width:560px) {
            grid-template-columns: repeat(2, 1fr);
        }

        .booking-horses-time-list__item {
            border-radius: 8px;
        }
    }
}


.booking-action {
    padding: 10px 15px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fff;
    border-top: 1px solid var(--border-color);
    z-index: -10;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    &.active {
        z-index: 100;
        opacity: 1;
        visibility: visible;
    }
}

.booking-action-inside {
    margin: 0 auto;
    max-width: 100%;
    width: 620px;
}


.booking-date-picker {
    margin-bottom: 50px;

    &__inside {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__content {
        max-width: 100%;
        display: inline-block;
    }

    &__month {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    &__arrow {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;


        svg {
            margin-top: -2px;
            display: block;
            width: 8px;
            height: auto;
        }
    }

    &__header,
    &__body {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;

        >div {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            transition: 0.3s;
        }
    }

    &__header {
        margin-bottom: 10px;
    }

    &__body {
        >div {
            border-radius: 100%;
            border: 1px solid var(--border-color);

            &:hover {
                color: #fff;
                border-color: var(--main-color2);
                background: var(--main-color2);
            }

            &.active {
                border-color: var(--main-color2);
            }

            &.disable {
                opacity: 0.5;
                cursor: not-allowed;
                color: #000;
                border-color: var(--border-color);
                background: none;
            }

            &.disable.active {
                border-color: var(--main-color2);
            }


        }
    }
}


.plug {
    text-align: center;

    &__icon {
        margin-bottom: 12px;
    }

    &__text {
        font-size: 18px;
    }
}

.grid-line {
    margin: 0 auto;
    grid-column: 1 / -1;
}


.booking-details {
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.05);

    &__title {
        padding: 20px 15px;
        font-size: 22px;
    }

    &__inside {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 1px solid var(--border-color);
    }

    &__horse {
        padding: 0 15px;
        padding-bottom: 20px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 20px;
        border-bottom: 1px solid var(--border-color);
    }

    &__horse-photo {
        margin-top: 6px;
        min-width: 80px;
        width: 80px;
        height: 80px;
        border-radius: 100%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    &__horse-info {
        position: relative;
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__horse-name {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__horse-time {
        line-height: 1.5;
        font-weight: 500;
    }



    &__servese {
        padding: 0 15px;
    }

    &__servese-price {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 500;
    }

    &__servese-name {
        font-size: 18px;

        span {
            padding-left: 4px;
            font-size: 16px;
            color: var(--icon-color);
        }
    }
}


.booking-form {
    margin-bottom: 50px;
    border-radius: 10px;
    box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.05);

    &__title {
        padding: 20px 15px;
        font-size: 22px;
    }

    &__inside {
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 1px solid var(--border-color);
    }

    &__user-data {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 18px;

        p {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            span {
                &:first-child {
                    color: var(--icon-color);
                }
            }
        }
    }
}


.booking-action-checkbox {
    margin-bottom: 8px;

    label {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        p {
            a {
                display: inline;
                color: var(--main-color2);
            }
        }
    }
}


.booking-popup-title {
    margin-bottom: 40px;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;

    p+p {
        margin-top: 12px;
    }
}

.booking-popup-text {
    margin-bottom: 12px;
    font-size: 18px;
    text-align: left;
    font-weight: 500;
}


.booking-popup-details {
    margin-bottom: 40px;
    padding-left: 20px;

    p {
        &+p {
            margin-top: 12px;
        }
    }
}

.booking-popup-actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;

    &--type2 {
        grid-template-columns: repeat(2, 1fr);
    }
}




.booking-season-tickets-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;



    &__item {
        width: 100%;
        display: flex;
        border: 2px solid transparent;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
        cursor: pointer;

        &.active {
            display: flex;
            border-color: var(--main-color2);


        }
    }

    &__item-photo {
        width: 100px;
        border-radius: 8px 0 0 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 8px 0 0 8px;
        }
    }

    &__item-info {
        width: calc(100% - 100px);
        position: relative;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__item-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__item-text {
        font-size: 16px;
        line-height: 1.5;
    }

    &__item-amount {
        font-size: 18px;
        font-weight: 500;
        color: var(--main-color2);
    }

    &__actions {
        margin-top: 12px;
        display: flex;
        gap: 24px;
    }

    &__actions-counter {
        display: none;
        align-items: center;
        gap: 8px;
    }

}

.input-counter {
    display: flex;
    align-items: center;
    gap: 12px;
}

.button-add-counter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    span {
        display: block;
        width: 100%;
        height: 2px;
        background: var(--main-color2);
    }

    &--plus {
        span {
            &:last-child {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
    }
}

.error {
    .orders-inventory-items {
        &__error {
            display: block;
        }
    }
}

.orders-inventory-items {
    padding: 40px 20px;
    padding-bottom: 20px;
    border-top: 2px solid var(--main-color2);

    @media screen and (max-width:1199px) {
        padding-left: 0;
        padding-right: 0;
    }

    &__error {
        display: none;
        margin-bottom: 16px;
        font-size: 16px;
        color: red;
        text-align: left;
    }

    &__title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 500;
        text-align: left;
    }

    &__header {
        display: flex;
        gap: 20px;

        @media screen and (max-width:560px) {
            flex-wrap: wrap;
        }

        >* {
            &:first-child {
                width: 100% !important;
            }

            @media screen and (max-width:560px) {
                width: 100% !important;
            }
        }
    }
}

.orders-inventory-items-table {
    margin-top: 24px;

    @media screen and (max-width:1199px) {
        .table-body {
            .tr {
                text-align: left;
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }
    }

    @media screen and (max-width:560px) {
        .button-add-counter {
            display: none;
        }
    }

}

.orders-inventory-items-sum {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 500;
    text-align: right;
}


.title-back {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    gap: 12px;



    .title {
        margin-bottom: 0;

        @media screen and (max-width:920px) {
            font-size: 24px;
        }
    }

    &__link {
        cursor: pointer;

        svg {
            display: block;
        }
    }
}



.view-elemet-wrap {
    margin-top: 36px;
    padding: 36px;
    width: 680px;
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);

    @media screen and (max-width:920px) {
        width: 100%;
        padding: 24px 12px;
    }

}

.view-elemet-list {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
        padding-bottom: 10px;
        width: 100%;
        display: flex;
        gap: 20px;
        font-size: 18px;
        line-height: 1.2;
        word-break: break-word;
        border-bottom: 1px solid var(--icon-color);

        @media screen and (max-width:560px) {
            padding: 8px;
            flex-wrap: wrap;
            gap: 8px;
        }

        p,
        span {
            width: calc(50% - 20px);

            @media screen and (max-width:920px) {
                width: 100%;
            }

            &:first-child {
                color: var(--icon-color);
            }
        }

    }
}

.booking-basket-wrap {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.booking-basket {
    border-radius: 10px;
    box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.05);

    &__title {
        padding: 15px;
        font-size: 20px;
    }

    &__inside {
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 1px solid var(--border-color);
    }
}

.booking-basket-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__item {
        width: 100%;
        display: flex;
        cursor: pointer;
    }

    &__item-photo {
        width: 100px;
        border-radius: 8px 0 0 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px 0 0 8px;
        }
    }

    &__item-info {
        width: calc(100% - 100px);
        position: relative;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media screen and (max-width:920px) {
            padding-right: 16px;
        }
    }

    &__item-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__item-amount {
        font-size: 16px;
        font-weight: 500;
        color: var(--main-color2);
    }

    &__actions {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);


    }
}


.booking-basket-total-amount {
    text-align: right;
    font-size: 20px;

    span {
        font-weight: 500;
    }
}

.form-action {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}






.home-page {
    padding: 25px 0;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.home-page-title {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width:920px) {
        font-size: 22px;
    }
}

.home-page-title-text {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width:920px) {
        font-size: 16px;
    }
}


.schedule {
    >div {
        max-width: 100%;
    }
}



.dashbord-content {
    padding: 24px;

    @media screen and (max-width:920px) {
        padding: 24px 12px;
    }

    .booking-wrap {
        margin: 25px auto;
        padding: 0;
        padding-bottom: 70px;
    }
}

.pay-button {
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width:520px) {
        flex-wrap: wrap;
    }
}



.collapse-btn {


    @media screen and (max-width:1199px) {
        right: 0;
        top: 0;
        position: absolute !important;
        z-index: 10;
    }
}

.table-scroll {

    @media screen and (max-width:1199px) {
        overflow-y: scroll;
    }
}

.table-wrap {
    padding: 24px 0;
}


.clients-nav {
    margin-bottom: 32px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    @media screen and (max-width:920px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width:520px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

.schedule-models-list {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: stretch;
    gap: 12px;

    @media screen and (max-width: 1480px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 860px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 560px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}


.schedule-horses-calendar {
    text-align: left;
}


.schedule-horses-date-select {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 1.5;
}


.schedule-title {
    margin-bottom: 12px;
    font-size: 16px;
}

.schedule-horses-block-wrap {
    overflow: hidden;
}

.schedule-wrap {
    overflow-x: auto;
}

.schedule {
    min-width: 820px;
}




// !!! DIMA
.table-detail-log {
    .view-elemet-list {
        &__item {
            text-align: left;

            &.not-equal {
                background: #ffd7d0;
            }

            &--header {
                border-width: 3px;

                span {
                    font-weight: bold;
                    color: black;

                    &:first-child {
                        color: black;
                    }
                }

            }
        }
    }
}

.flexible-form {
    .inside {
        width: initial;
    }
}

/* .MuiBox-root {
    @media screen and (max-width:920px) {
        padding: 24px 12px !important;
    }
} */


.create-edit-form {
    .close-modal {
        top: -12px;
        right: -6px;
    }
}

.close-modal {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

    svg {
        path {
            transition: 0.3s;
        }
    }

    &:hover {
        svg {
            path {
                fill: var(--main-color2);
            }
        }
    }
}


.MuiPickersLayout-root {
    display: block !important;
}


.input-phone-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
}