.dashbord-wrapper {
    min-height: 100vh;
    display: flex;
    position: relative;
}

.sidebar {
    padding: 12px 8px;
    min-height: 100vh;
    width: 260px;
    border-right: 1px solid #ddd;
    transition: 0.3s;

    &.active {
        width: 80px;

        .date-calendar {
            display: none;
        }

        .dashbord-nav {
            &__item-link {
                font-size: 0;
            }

            p,
            span {
                display: none;
            }
        }

    }

    .icon {
        width: 27px;
        height: 27px;
        opacity: .6;
    }

    @media screen and (max-width:1199px) {
        position: absolute;
        left: -260px;
        top: 0;
        height: 100%;
        z-index: 100;
        background: #fff;

        &.active {
            left: 0;
            width: 260px;

            .date-calendar {
                display: block;
            }

            .dashbord-nav {
                &__item-link {
                    font-size: 14px;
                }

                p,
                span {
                    display: block;
                }
            }
        }
    }

    .toggler {
        display: none;

        @media screen and (max-width:1199px) {
            display: flex;
            margin: 16px 0;
            margin-left: auto;
        }
    }
}

.dashbord {
    min-height: 100vh;
    width: calc(100% - 260px);
    transition: 0.3s;

    &.active {
        width: calc(100% - 80px);
    }

    @media screen and (max-width:1199px) {
        width: 100%;

        &.active {
            width: 100%;
        }
    }
}


.dashbord-nav {
    margin-bottom: 24px;

    &__item-link {
        display: block;
        color: var(--main-color);

        span {
            transition: 0.3s;
        }
    }

}

.activeLink {
    color: var(--main-color2);

    svg {
        path {
            fill: var(--main-color2)
        }
    }
}


.dashbord-action {
    display: flex;
    justify-content: center;
}


.header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 12px;
}




.toggler {
    position: relative;
    width: 32px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;


    span {
        width: 100%;
        height: 3px;
        display: block;
        background-color: var(--main-color2);
        border-radius: 10px;
        transition: 0.3s;
    }

    &.active {
        span {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);


            &:first-child {
                transform: translateY(-50%) rotate(-45deg);
            }

            &:last-child {
                visibility: hidden;
            }
        }
    }

    @media screen and (min-width:1200px) {
        span {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);


            &:first-child {
                transform: translateY(-50%) rotate(-45deg);
            }

            &:last-child {
                visibility: hidden;
            }
        }

        &.active {
            span {
                position: relative;
                left: 0;
                top: 0;
                transform: none;

                &:first-child {
                    transform: none;
                }

                &:last-child {
                    visibility: visible;
                }
            }
        }
    }

}