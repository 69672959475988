.roleInfo {
  display: flex;
  flex-direction: column;
}

.login {
  font-size: .8rem;
}
.role {
  font-weight: bold;
  font-size: 0.9rem;
}

@media (max-width: 380px) {
  .login,
  .role {
    font-size: 0.7rem;
  }
}